<template>
  <div class="columns">
    <div class="column w-full" :class="{'xl:w-4/6 xxxl:w-4/5': showNotes}">
      <div class="columns" v-if="ready">
        <div class="column w-full" v-if="false">
          <el-tabs class="custom-tabs-v1 is-right" ref="tabs" active-name="Sites">
            <el-tab-pane label="Sites" name="Sites">
              <sites @onload="loading = false"/>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="column w-full">
          <div class="flex flex-wrap items-center w-full">
            <div class="flex-grow">
              <el-select v-model="selectedLinkDevs" multiple v-if="linkDevs.length" class="w-full" filterable placeholder="Filter users...">
                <el-option v-for="(user, i) in linkDevs" :key="i" :value="user.id" :label="`${user.firstname} ${user.lastname}`" />
              </el-select>
            </div>
            <div>
              <el-date-picker
                v-model="datePicker"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                :picker-options="pickerOptions"
                :clearable="false"
                format="dd MMM yyyy"
                @change="setDateStartAndEnd"
              >
              </el-date-picker>

            </div>
            <div class="w-full flex w-full justify-end gap-4">
              <div>
                <el-button @click="toggleSidebar" type="primary">
                  {{showNotes ? 'Hide notes' : 'Show notes'}}
                </el-button>
              </div>
              <div>
                <el-button @click="include_suspended = !include_suspended" type="primary">
                  {{ include_suspended ? 'Hide suspended' : 'Show suspended' }} sites
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <template v-if="!selectedLinkDevs.length">
          <div class="column w-full" v-if="showComponents">
            <div v-if="!isContentEditorOnly">
              <link-dev-progess  :date-start="dateStart" :date-end="dateEnd" :include-suspended-sites="include_suspended" />
              <link-dev-leads  :date-start="dateStart" :date-end="dateEnd"  :include-suspended-sites="include_suspended" />
              <unread-email-leads  :include-suspended-sites="include_suspended" />
              <email-accounts />
              <link-building-tasks :include-suspended-sites="include_suspended" />
              <ContentWriterContent :date-start="dateStart" :date-end="dateEnd" />
            </div>
            <div v-else>
              <ContentWriterContent :date-start="dateStart" :date-end="dateEnd" />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="column w-full" v-for="(user, i ) in selectedLinkDevs" :key="i">
            <div v-if="!isContentEditorOnly && showComponents">
              <link-dev-progess :title="`${getUserNiceName(user)} Progress`" :date-start="dateStart" :date-end="dateEnd" :user-id="user" :include-suspended-sites="include_suspended" />
              <link-dev-leads :title="`${getUserNiceName(user)} Leads`" :user-id="user" :date-start="dateStart" :date-end="dateEnd"  :include-suspended-sites="include_suspended" />
              <unread-email-leads :title="`${getUserNiceName(user)} Unread Emails`" :user-id="user"  :include-suspended-sites="include_suspended" />
              <email-accounts :title="`${getUserNiceName(user)} Email Accounts`" :user-id="user" :date-start="dateStart" :date-end="dateEnd" />
              <link-building-tasks :title="`${getUserNiceName(user)} Link Builder Tasks`" :user-id="user" :include-suspended-sites="include_suspended"  />
              <ContentWriterContent :title="`${getUserNiceName(user)} Content`" :date-start="dateStart" :date-end="dateEnd" :user-id="user" :include-suspended-sites="include_suspended"  />
            </div>
            <div v-else-if="isContentEditorOnly && showComponents">
              <ContentWriterContent :title="`${getUserNiceName(user)} Content`" :date-start="dateStart" :date-end="dateEnd" :user-id="user"  :include-suspended-sites="include_suspended" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="column w-full xl:w-2/6 xxxl:w-1/5" v-if="showNotes">
      <trieste-card title="Notes">
        <div class="p-4">
          <notifications :show-close="false" item-type="note" />
        </div>
      </trieste-card>
      <!--
      <trieste-card title="System Status">
        <div class="p-4">
          <div class="flex gap-2">
            <div>Queues</div>
            <div>d</div>
          </div>
          <div class="flex gap-2">
            <div>SERP Provider</div>
            <div class="ml-auto">d</div>
          </div>
          <div class="flex gap-2">
            <div>Ahrefs</div>
            <div>d</div>
          </div>
          <div class="flex gap-2">
            <div>Email Hunter</div>
            <div>d</div>
          </div>
        </div>
      </trieste-card>
      -->
    </div>
  </div>
</template>
<script>
import Sites from "@/components/views/Home/Tabs/Sites";
import LinkDevProgess from "@/components/views/Home/LinkDev/Progress";
import LinkDevLeads from "@/components/views/Home/LinkDev/Leads";
import UnreadEmailLeads from "@/components/views/Home/LinkDev/UnreadEmails";
import EmailAccounts from "@/components/views/Home/LinkDev/EmailAccounts";
import LinkBuildingTasks from "@/components/views/Home/LinkBuildingTasks";
import ContentWriterContent from "@/components/views/Home/ContentWriter/Content";
import Notifications from "@/components/incs/Notifications";
import { datePickerOptions } from "@/utils/helpers";
const moment = require('moment');
export default {
  name: 'CompanyDashboard',
  components: {
    Notifications,
    LinkBuildingTasks, Sites, LinkDevProgess, LinkDevLeads, UnreadEmailLeads, EmailAccounts, ContentWriterContent},
  data() {
    const dtmEndDate = moment().toISOString()
    const dtmStartDate = moment().startOf('month').format('YYYY-MM-DD')
    return {
      loading: false,
      datePicker: [dtmStartDate, dtmEndDate],
      dateStart: dtmStartDate,
      dateEnd: dtmEndDate,
      linkDevs: [],
      selectedLinkDevs: [],
      showNotes: true,
      showComponents: true,
      pickerOptions: datePickerOptions,
      ready: false,
      include_suspended: false,
    }
  },
  mounted() {
    this.$store.dispatch('company/getCompany', {companyId: this.$store.state.company.company.id}).then(company => {
      this.$store.commit('global/setBreadcrumbs', [
        {
          path: '/',
          label: company.name
        }
      ])
    })
    this.selectedLinkDevs = [this.$store.state.auth.user.id];
    this.getResources()
  },
  computed: {
    isContentEditorOnly() {
      const otherRoles = this.$store.state.auth.user.roles.filter(r => !['content editor', 'content writer'].includes(r))
      if(otherRoles.length) {
        return false
      }
      return true;
    }
  },
  methods: {
    setDateStartAndEnd() {
      this.dateStart = moment(this.datePicker[0]).format('YYYY-MM-DD')
      this.dateEnd = moment(this.datePicker[1]).format('YYYY-MM-DD')
    },
    toggleSidebar() {
      this.showNotes = !this.showNotes
      this.showComponents = false;
      setTimeout(() => {
        this.showComponents = true
      }, 100)
    },
    getUserNiceName(id) {
      const user = this.linkDevs.find(l => l.id === id)
      if(!user) {
        return 'N/A'
      }
      return `${user.firstname} ${user.lastname}`;
    },
    getResources() {
      this.$store.dispatch('users/loadAll', {}).then(r => {
        this.linkDevs = r
        this.ready = true
      })
    },
  }
}
</script>
<style lang="scss">
.home-area {
  .custom-datatable-1 {
    a, i {
      @apply font-bold
    }
  }
}
</style>
