<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        Payments
      </h1>
    </div>
    <el-tabs class="custom-tabs-v1">
      <el-tab-pane label="Active payments" v-if="false">
        <trieste-table
          item-type="payments"
          title="Payments"
          :end-point="`/v1/companies/${$store.state.company.company.id}/payments`"
          :fields="fields"
          ref="table"
          default-sort-prop="created_at"
          default-sort-direction="desc"
          :show-title="false"
          saved-table-sort-name="payments"
        >
          <template v-slot:link-slot="props">
            <a :href="`/clients/${props.row.client_id}/sites/${props.row.site_id}/campaigns/${props.row.campaign_id}/leads/${props.row.id}`">
              <div  class="flex flex-row items-center font-bold">
                <logo-widget :size="16"  v-model="props.row.host"/>
                <div class="ml-1">{{ props.row.from_url }}</div>
              </div>
            </a>
          </template>
          <template v-slot:actions-slot="props">
            <el-button
              v-if="false"
              size="small"
              icon="el-icon-view"
              @click="openWidget(props.row.id)"
              type="primary">
              View
            </el-button>
            <a :href="`/company/${$route.params.companyId}/leads/${props.row.id}`">
              <el-button
                size="small"
                icon="el-icon-view"
                type="primary">
                View
              </el-button>
            </a>
          </template>
        </trieste-table>
        <subscription-payments-widget @payment-create="refreshTable"  :subscription-id="currentSubscriptionId" :visible.sync="showWidget" v-if="showWidget && false" @on-load="loading = false" />
      </el-tab-pane>
      <el-tab-pane label="Awaiting payment" v-if="$store.getters['auth/hasPermission']('lead.read')">
        <links :active-only="true"
               default-sort-prop="urgent"
               :show-title="false" status="awaiting_payment" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import Styling from './../../../components/vuetable.config';
  import VueTableControlsMixin from './../../../components/mixins/vuetable-controls';
  import LogoWidget from "../../../components/incs/LogoWidget";
  import SubscriptionPaymentsWidget from "../../../components/incs/SubscriptionPaymentsWidget";
  import Links from "../Links/Index";
  export default {
    metaInfo() {
      return {
        title: 'Awaiting payments'
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: 'Home'
          },
          {
            label: 'Payments'
          }
        ])
      })
    },
    components: {SubscriptionPaymentsWidget, LogoWidget, Links},
    mixins: [VueTableControlsMixin],
    data: function() {
      return {
        currentSubscriptionId: null,
        showWidget: false,
        loading: false,
        query: {
          rpp: 20,
          page: 1
        },
        fields: [
          {
            name: 'link-slot',
            title: 'Site Link',
          },
          {
            name: 'price',
            title: 'price',
            sortField: 'price',
            formatter: (val) => val.toLocaleString()
          },
          {
            name: 'price_currency',
            title: 'Currency'
          },
          {
            name: 'date_paid',
            title: 'Payment Date',
            sortField: 'date_paid',
            dateField: true,
          },
          {
            title: '',
            name: 'actions-slot',
            dateField: true,
            width: '110px',
            fixed: 'right'
          },
        ],
        sortOrder: [
          {
            field: 'name',
            direction: 'asc'
          }
        ],
        styling: {
          table: Styling.table,
          pagination: Styling.pagination
        },
      }
    },
    computed: {
      getFilterCount() {
        let count = 0;
        Object.keys(this.search_params).forEach(k => {
          if(this.search_params[k].length > 0) {
            count++;
          }
        })
        return count;
      }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('link.read')) {
        this.$message.error('You do not have permissions to view link payments.');
        this.$router.push('/');
      } else {
        this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
          this.$store.commit('global/setBreadcrumbs', [
            {
              path: '/',
              label: company.name
            },
            {
              label: 'Payments'
            }
          ])
        })
      }
    },
    methods: {
      openWidget(id) {
        this.loading = true;
        this.currentSubscriptionId = id;
        this.showWidget = true;
      },
      refreshTable() {
        this.$refs.table.getData()
      },
      resetFields() {
        this.search_params = {
          created_by_id: [],
          owner_id: [],
          site_target_id: [],
          site_id: [],
          status: [],
          type: [],
        }
        this.refresh()
      }
    }
  }
</script>
