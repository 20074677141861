<template>
  <div>
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/dashboard/linkdev/unread-emails`"
      :params="{ user_id: userId, include_suspended: includeSuspendedSites }" :fields="fields"
      :title="title || 'Unread emails'" :show-sub-title="false" collapsable first-field-total
    >
      <template v-slot:domain="props">
        <span v-if="props.$index === 0" class="font-bold">Total</span>
        <a :href="`/company/${$store.state.company.company.id}/sites/${props.row.id}`" v-else>{{props.row.domain}}</a>
      </template>
      <template v-slot:client="props">
        <span v-if="props.$index === 0" class="font-bold">Total</span>
        <a :href="`/company/${$store.state.company.company.id}/clients/${props.row.client.id}`" v-else>{{props.row.client.name}}</a>
      </template>
      <template v-slot:unread-slot="props">
      <!--  <span v-if="props.$index === 0">
          {{props.row.unread_count.toLocaleString()}}
          <span v-if="props.row.old_unread_count">
            ({{props.row.old_unread_count.toLocaleString()}} old)
          </span>
        </span> -->
        <a :href="buildLeadLink(props.row.id)">
          {{props.row.unread_count.toLocaleString()}}
          <span v-if="props.row.old_unread_count">
            ({{props.row.old_unread_count.toLocaleString()}} old)
          </span>
        </a>
      </template>
      <template v-slot:negotiation-slot="props">
        <a :href="buildLeadLink(props.row.id, 'negotiation')">
          {{props.row.negotiation_count.toLocaleString()}}
          <span v-if="props.row.old_negotiation_count">
            ({{props.row.old_negotiation_count.toLocaleString()}} old)
          </span>
        </a>
      </template>
      <template v-slot:rejected-slot="props">
        <a :href="buildLeadLink(props.row.id, ['dropped', 'rejected'])">
          {{props.row.rejected_count.toLocaleString()}}
          <span v-if="props.row.old_rejected_count">
            ({{props.row.old_rejected_count.toLocaleString()}} old)
          </span>
        </a>
      </template>
      <template v-slot:on-hold-slot="props">
        <a :href="buildLeadLink(props.row.id, 'on_hold')">
          {{props.row.on_hold_count.toLocaleString()}}
          <span v-if="props.row.old_on_hold_count">
            ({{props.row.old_on_hold_count.toLocaleString()}} old)
          </span>
        </a>
      </template>
      <template v-slot:content-slot="props">
        <a :href="buildLeadLink(props.row.id, ['content_available', 'awaiting_content'])">
          {{props.row.content_count.toLocaleString()}}
          <span v-if="props.row.old_content_count">
            ({{props.row.old_content_count.toLocaleString()}} old)
          </span>
        </a>
      </template>
      <template v-slot:placement-slot="props">
        <a :href="buildLeadLink(props.row.id, 'awaiting_placement')">
          {{props.row.placement_count.toLocaleString()}}
          <span v-if="props.row.old_placement_count">
            ({{props.row.old_placement_count.toLocaleString()}} old)
          </span>
        </a>
      </template>
      <template v-slot:correction-slot="props">
        <a :href="buildLeadLink(props.row.id, ['correction', 'correction_waiting_for_reply'])">
          {{props.row.correction_count.toLocaleString()}}
          <span v-if="props.row.old_correction_count">
            ({{props.row.old_correction_count.toLocaleString()}} old)
          </span>
        </a>
      </template>
      <template v-slot:payment-slot="props">
        <a :href="buildLeadLink(props.row.id, ['awaiting_payment', 'waiting_for_tier_3_approval'])">
          {{props.row.payment_count.toLocaleString()}}
          <span v-if="props.row.old_payment_count">
            ({{props.row.old_payment_count.toLocaleString()}} old)
          </span>
        </a>
      </template>
      <template v-slot:active-slot="props">
        <a :href="buildLeadLink(props.row.id, ['active', 'active_renewal'])">
          {{props.row.active_count.toLocaleString()}}
          <span v-if="props.row.old_active_count">
            ({{props.row.old_active_count.toLocaleString()}} old)
          </span>
        </a>
      </template>
    </trieste-table>
  </div>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        default: null
      },
      userId: {
        type: [String, Number],
        default: null
      },
      includeSuspendedSites: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        fields: [
          {
            title: 'domain',
            name: 'domain',
            sortField: 'domain',
          },
          {
            title: 'Client',
            name: 'client',
            sortField: 'client.name',
            formatter: (val) => val ? 'yeh' : 'eh?'
          },
          {
            title: 'All unread',
            name: 'unread-slot'
          },
          {
            title: 'Negotiation',
            name: 'negotiation-slot'
          },
          {
            title: 'Rejected/Dropped',
            name: 'rejected-slot'
          },
          {
            title: 'On hold',
            name: 'on-hold-slot'
          },
          {
            title: 'Content',
            name: 'content-slot'
          },
          {
            title: 'Placement',
            name: 'placement-slot'
          },
          {
            title: 'Correction',
            name: 'correction-slot'
          },
          {
            title: 'T3/Payment',
            name: 'payment-slot'
          },
          {
            title: 'Active',
            name: 'active-slot'
          },
        ],
        batchCreate: {
          siteId: null,
          clientId: null,
          show: false
        }
      }
    },
    methods: {
      buildAllLink(row) {
        const base_link = `/company/${this.$store.state.company.company.id}/sites/${row.id}?tab=unread%20emails`
        if(!this.userId) {
          return base_link
        }

        return base_link + '&filters=' + JSON.stringify({ User: [this.userId] })
      },
      buildLeadLink(siteId, status) {
        let baseLink  = `/company/${this.$store.state.company.company.id}/sites/${siteId}?tab=leads`;
        if(!siteId) {
          baseLink = `/company/${this.$store.state.company.company.id}/leads`;
        }
        const filters = {}
        if(this.userId) {
          filters['Owner'] = [this.userId]
        }
        if(status) {
          filters['status'] = Array.isArray(status) ? status : [status]
        }

        filters['Unread emails'] = true

        return baseLink + (siteId ? '&' : '?') + '&filters=' + JSON.stringify(filters)
      }
    },
  }
</script>
