<template>
  <div v-loading="loading">
    <trieste-card title="Email accounts">
      <div class="p-4">
        <ul>
          <li v-for="(account, i) in emailAccounts" :key="i">
            <el-checkbox-group v-model="selectedItems" :disabled="!canUpdate">
              <el-checkbox :label="account.id" @change="(v) => startMultiSelect(v, i, selectedItems, emailAccounts)">{{ account.email }}</el-checkbox>
            </el-checkbox-group>
          </li>
        </ul>
      </div>
      <template v-slot:footer v-if="canUpdate">
        <div class="text-right">
          <el-button type="primary" icon="el-icon-edit-outline" size="small" @click="update">Update</el-button>
        </div>
      </template>
    </trieste-card>
  </div>
</template>
<script>
import shiftSelectCheckboxes from '@/components/mixins/shift_select_checkboxes'
  export default {
    mixins: [shiftSelectCheckboxes],
    props: {
      items: {
        type: Array,
        default: () => []
      },
      siteId: {
        type: [String, Number],
        required: true
      }
    },
    data() {
      return {
        emailAccounts: [],
        loading: false,
        selectedItems: this.items
      }
    },
    computed: {
      canUpdate() {
        return this.$store.getters['auth/hasPermission']('emailaccount.update')
      }
    },
    mounted() {
      this.getResources()
    },
    methods: {
      getResources() {
        this.loading = true
        const resourceCalls = [
          this.$http.get(`/v1/companies/${this.$route.params.companyId}/email-accounts`, {
            params: {
              all: true,
              include_last_sent_email: false,
              include_last_received_email: false,
              include_sites: false,
              only_enabled: true
            }
          })
        ]

        this.$http.all(resourceCalls).then(this.$http.spread((emailAccounts) => {
          this.emailAccounts = emailAccounts.data.data
        }))
          .finally(() => {
            this.loading = false
          })
      },
      update() {
        this.loading = true
        this.$http.post(`/v1/companies/${this.$route.params.companyId}/sites/${this.siteId}/email-accounts/bulkAssign`, {
          accounts: this.selectedItems
        }).then(() => {
          this.$emit('on-update')
          this.$message.success('Successfully updated the allowed email accounts.')
        }).catch(() => {
          this.$message.error('Failed to update the allowed email accounts.')
        })
        .finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>
