<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        <logo
          v-model="$store.state.site.site.base_url"
          class="mr-2"
          :size="30"
        />
        <span>{{ $store.state.site.site.domain }}<span class="faded-text"><span class="faded-strike">/</span>Site</span></span>
      </h1>
      <div class="controls">
        <div class="inline-block mr-2"  v-if="ready"><bulk-status-button :default-site="$store.state.site.site.id" /></div>
        <el-button type="success" icon="el-icon-edit" size="mini" @click="show_settings = true" v-if="$store.getters['auth/hasPermission']('site.update')">Edit</el-button>
        <el-button type="info" icon="el-icon-document-copy" size="mini" @click="show_clone_site = true" v-if="$store.getters['auth/hasPermission']('site.create')">Clone Site</el-button>
      </div>
    </div>
    <div class="columns">
      <div class="column w-full">
        <el-tabs v-model="activeTabName" class="custom-tabs-v1" @tab-click="tabsChanged" ref="tabs">
          <el-tab-pane label="Campaigns" name="campaigns" v-if="$store.getters['auth/hasPermission']('campaign.read')">
             <span slot="label">
               <a href="?tab=campaigns" @click.prevent>Campaigns</a>
             </span>
            <campaigns-index />
          </el-tab-pane>
          <el-tab-pane label="Site targets" name="site targets" v-if="$store.getters['auth/hasPermission']('target.read')">
             <span slot="label">
               <a href="?tab=site targets" @click.prevent>Site targets</a>
             </span>
            <targets />
          </el-tab-pane>
          <el-tab-pane label="Links" name="links" v-if="$store.getters['auth/hasPermission']('link.read')">
             <span slot="label">
               <a href="?tab=links" @click.prevent>Links</a>
             </span>
            <links export-mode="BATCHLINKS" export-name="all batch links" :show-title="false" allow-mass-update  />
          </el-tab-pane>
          <el-tab-pane label="Leads" name="leads" v-if="$store.getters['auth/hasPermission']('lead.read')">
             <span slot="label">
               <a href="?tab=leads" @click.prevent>Leads</a>
             </span>
            <links :active-only="true" :allow-selection.sync="allowUnreadSelection" @on-load="checkLeadLoad" :selected-rows.sync="selected_lead_rows" :show-title="false" include-exchange-prices export-mode="LINKBUILDING" ref="leads" export-name="all approved leads">
              <template v-slot:header v-if="allowUnreadSelection">
                <el-button type="success" size="medium" v-if="selected_lead_rows.length" @click="markAsRead">Mark as read</el-button>
              </template>
            </links>
          </el-tab-pane>
          <el-tab-pane label="Unread emails" name="unread emails">
             <span slot="label">
               <a href="?tab=unread emails" @click.prevent>Unread emails</a>
             </span>
            <email-list
              :end-point="`/v1/companies/${$route.params.companyId}/sites/${$store.state.site.site.id}/emails`"
              type="incoming"
              custom-title="Unread emails"
              v-if="!loading"
              :params="{unread: true}"
              :include-site="false"
            />
          </el-tab-pane>
          <el-tab-pane label="Blacklist domains" name="blacklist domains">
             <span slot="label">
               <a href="?tab=blacklist domains" @click.prevent>Blacklist domains</a>
             </span>
            <blacklisted-domains />
          </el-tab-pane>
          <el-tab-pane label="Info" name="info">
             <span slot="label">
               <a href="?tab=info" @click.prevent>Info</a>
             </span>
            <div class="max-w-screen-xl">
              <trieste-card title="Details">
                <div class="p-4">
                  <trieste-info-column label="Base Host" :value="$store.state.site.site.base_host"  />
                  <trieste-info-column label="Base Url" :value="$store.state.site.site.base_url"  />
                  <trieste-info-column label="Domain" :value="$store.state.site.site.domain"  />
                  <trieste-info-column label="Status" :value="$store.state.site.site.status"  />
                </div>
              </trieste-card>
              <site-email-templates :items="getTemplateIds" v-if="new_site.id && ready" :site-id="new_site.id" @on-update="reloadSite" />
              <email-accounts v-if="new_site.id && ready" :site-id="new_site.id" :items="getEmailAccountIds" @on-update="reloadSite" />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <create-edit v-if="!loading && new_site.id && $store.getters['auth/hasPermission']('site.update')" v-model="new_site" :show.sync="show_settings" />
    <clone-site :site="new_site" v-if="new_site.id" :show.sync="show_clone_site" />
  </div>
</template>
<script>
  import Targets from './Targets/Index';
  import CampaignsIndex from './Campaigns/Index';
  import Links from '../Links/Index'
  import CreateEdit from "../../../components/views/Sites/CreateEdit";
  import BlacklistedDomains from "./Tabs/BlacklistedDomains";
  import tabPosition from '@/components/mixins/tab_position';
  import EmailList from "@/components/views/emails/EmailList";
  import SiteEmailTemplates from "@/components/views/Sites/EmailTemplates"
  import EmailAccounts from "@/components/views/Sites/EmailAccounts";
  import BulkStatusButton from '@/components/views/links/BulkStatusButton';
  import CloneSite from "@/components/views/Sites/CloneSite";
  export default {
    name: 'ViewSite',
    metaInfo() {
      return {
        title: this.tabTitle,
        titleTemplate: '%s | Sites'
      }
    },
    components: {
      EmailAccounts,
      EmailList, CreateEdit, CampaignsIndex, Targets, Links, BlacklistedDomains, SiteEmailTemplates, BulkStatusButton, CloneSite },
    mixins: [tabPosition],
    data() {
      return {
        loading: true,
        ready: false,
        show_settings: false,
        updating_settings: false,
        show_clone_site: false,
        activeTabName: "campaigns",
        tabNames: ['campaigns', 'targets'],
        allowUnreadSelection: false,
        new_site: Object.assign({}, this.$store.state.site.site),
        new_site_rules: {
          base_url: [
            {required: true, min: 3, type: 'url', trigger: 'blur', message: 'Please enter a valid website URL'}
          ],
          status: [
            {required: true, message: 'Please select a valid status', trigger: 'blur'}
          ],
        },
        selected_lead_rows: []
      }
    },
    computed: {
      getTemplateIds() {
        if( this.new_site.templates && this.new_site.templates.length ) {
          return this.new_site.templates.map(i => i.id)
        }
        return []
      },
      getEmailAccountIds() {
        if( this.new_site.email_accounts && this.new_site.email_accounts.length ) {
          return this.new_site.email_accounts.map(i => i.id)
        }
        return []
      },
      tabTitle() { return  this.new_site.domain ? this.new_site.domain : this.$store.state.company.company.name }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('site.read')) {
        this.$message.error('You do not have permissions to view sites.');
        this.$router.push('/');
      }
      this.loading = true;
      this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
          this.$store.dispatch('site/getSite', this.$route.params).then((site) => {
            this.$store.dispatch('client/getClient', { clientId: site.client_id }).then((client) => {
              this.$store.commit('global/setBreadcrumbs', [
                {
                  path: '/',
                  label: company.name
                },
                {
                  path: `/company/${company.id}/clients`,
                  label: 'clients'
                },
                {
                  path: `/company/${company.id}/clients/${client.id}`,
                  label: client.name
                },
                {
                  path: `/company/${company.id}/clients/${client.id}?tab=sites`,
                  label: 'sites'
                },
                {
                  path: `/company/${company.id}/sites/${site.id}`,
                  label: site.domain
                },
                {
                  label: this.activeTabName[0].toUpperCase() + this.activeTabName.substring(1)
                }
              ])
              this.new_site = site
              this.loading = false
              this.ready = true
            })
          })
      })
    },
    methods: {
      reloadSite () {
        this.$store.dispatch('site/getSite', this.$route.params);
      },
      updateSite() {
        setTimeout(() => {
          this.$store.dispatch('site/getSite', this.$route.params).then(() => {
            this.new_site = Object.assign({}, this.$store.state.site.site)
          })
        }, 200)
      },
      checkLeadLoad(v) {
        this.allowUnreadSelection = !!this.$refs?.leads?.$refs?.table?.getFilterData()?.unread_emails
      },
      markAsRead() {
        const ids = this.selected_lead_rows.map(r => r.id)
        if(ids.length) {
          this.loading = true;
          this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/links/mass-update`, {
            ids,
            mark_emails_unread: true
          })
          .then(() => {
            this.$message.success('Successfully marked unread emails as read.')
            this.$refs.leads.refresh()
            this.selected_lead_rows = [];
          })
          .catch(() => { this.$message.error('An error occurred performing that request.') })
          .finally(() => this.loading = false)
        }
      }
    }
  }
</script>
