<template>
  <div v-loading="loading" ref="top">

    <site-requirements v-if="loaded" :site-id="$store.state.site.site.id" />
    <trieste-card :title="(mode === 'create' ? 'Create a' : 'Edit') + ' batch'">
      <div class="p-4">
        <project-and-target-form
          form-label-width="300px"
          :project-id.sync="batch.default_project_id"
          :show-project-select="false"
          :ignore-suspended-targets="true"
          :target-id.sync="batch.default_site_target_id"
          :site-id.sync="$store.state.site.site.id"
          ref="projectAndTargetForm"
          v-if="loaded"
        />
        <el-form
          ref="campaignForm"
          :model="batch"
          :rules="new_batch_rules"
          label-width="300px"
          label-position="left"
          :disabled="!!!updatable"
          v-if="loaded"
        >
          <el-form-item label="Owner" prop="owner_id">
            <el-select v-model="batch.owner_id" filterable class="w-full">
              <el-option
                v-for="(cu, i) in company_users"
                :key="'cu_'+i"
                :value="cu.id"
                :label="`${cu.firstname} ${cu.lastname}`"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Campaign" prop="campaign_id" v-if="show_campaign_select">
            <el-select v-model="batch.campaign_id" class="w-full">
              <el-option v-for="(campaign, i) in campaigns" :key="i" :value="campaign.id" :label="campaign.name" />
            </el-select>
          </el-form-item>
          <el-form-item
            label="Name"
            prop="name"
          >
            <el-input
              v-model="batch.name"
            />
          </el-form-item>
          <el-form-item label="Batch type" prop="batch_type">
            <el-select v-model="batch.batch_type" :disabled="mode !== 'create'">
              <el-option value="google_simple_query_v2" label="Google Serp query"  />
              <el-option value="bing_search_api" label="Bing Serp query"  />
              <el-option value="yahoo" label="Yahoo Serp query"  />
              <el-option value="existing" label="Existing"  />
              <el-option value="content_analysis" label="Content Analysis"  />
              <el-option value="baidu_search_api" label="Baidu Serp query"  />
              <el-option value="domain" label="Domain backlinks"  />
              <el-option value="single_page" label="Page backlinks"  />
              <el-option value="page_url" label="External links on a page"  />
              <el-option value="html_data" label="Custom URL list"  />
            </el-select>
          </el-form-item>

          <el-form-item label="Content Keywords" prop="keyword" v-if="batch.batch_type === 'content_analysis'">
            <el-input
              type="textarea"
              rows="5"
              v-model="batch.content_keywords" />
            <small>Enter each keyword in a new line a maximum of 5.</small>
          </el-form-item>

          <el-form-item label="Content Language" prop="language" v-if="batch.batch_type === 'content_analysis'">
            <el-select v-model="batch.content_language">
              <el-option
                v-for="(se, i) in available_langs"
                :key="'lang' + i"
                :value="se.value"
                :label="se.label"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Content Country" prop="country" v-if="batch.batch_type === 'content_analysis'">
            <el-select v-model="batch.content_country">
              <el-option
                v-for="(se, i) in available_countries"
                :key="'lang' + i"
                :value="se.value"
                :label="se.label"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="['bing_search_api', 'google_simple_query_v2', 'yahoo'].includes(batch.batch_type) && mode === 'create'"
            label="Search engine"
            prop="seid"
            required

          >
            <el-select
              v-model="batch.seid"
              :disabled="mode !== 'create'"
            >
              <el-option
                v-for="(se, i) in available_search_engines"
                :key="'search_engines' + i"
                :value="se.value"
                :label="se.label"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="Keywords"
            prop="google_simple_query"
            required
            v-if="['bing_search_api', 'google_simple_query_v2', 'baidu_search_api', 'yahoo'].includes(batch.batch_type)"
          >
            <div class="columns">
              <div class="column w-full">
                <el-input
                  v-model="batch.google_simple_query"
                  size="small"
                  type="textarea"
                  :disabled="mode !== 'create'"
                  :rows="10"
                />
                <small>Enter each keyword in a new line.</small>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="URL list"
            prop="html_data"
            required
            v-if="batch.batch_type === 'html_data'"
          >
            <div class="columns">
              <div class="column w-full">
                <textarea v-model="batch.html_data" rows="10" class="block w-full border-gray-400 border rounded py-2 p-4" @paste="checkCustomUrlListValuesOnPaste" />
                <small>Enter each URL in a new line.</small>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="Auto accept" prop="auto_accept" v-if="canSetDefaultStatus">
            <el-switch v-model="batch.auto_accept" :disabled="mode !== 'create'" />
          </el-form-item>
          <el-form-item label="Auto accept status" prop="auto_accept" v-if="canSetDefaultStatus">
            <el-select
              v-model="batch.auto_accept_status"
              filterable
              :disabled="mode !== 'create' || !batch.auto_accept"
            >
              <el-option
                v-for="(o, i) in $store.state.link.statuses"
                :key="i"
                :value="o.value"
                :label="o.label"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="domain" prop="domain" v-if="batch.batch_type === 'domain'">
            <el-input v-model="batch.domain" />
          </el-form-item>
          <el-form-item label="Page url" prop="page_url" v-if="['single_page', 'page_url'].includes(batch.batch_type)">
            <el-input v-model="batch.page_url" />
          </el-form-item>
          <el-form-item
            label="Run Every"
            prop="run_every_interval_days"
            v-if="false"
          >
            <el-select v-model="batch.run_every_interval_days">
              <el-option :value="null" label="Do not run automatically" />
              <el-option v-for="(d, k) in [1,2,3,4,5,6,7]" :key="'interval_' + k" :value="d" :label="`${d} days`" />
            </el-select>
          </el-form-item>
          <el-form-item label="Allowed leads per domain">
            <div style="max-width: 200px">
              <el-input type="number" v-model.number="batch.max_link_leads_per_domain" />
            </div>
          </el-form-item>

          <el-form-item label="Minimum Ahrefs Domain Rating">
            <div style="max-width: 200px">
              <el-input type="number" v-model="batch.filter_ahrefs_domain_rating" />
            </div>
          </el-form-item>
          <el-form-item label="Minimum Ahrefs Traffic Score">
            <div style="max-width: 200px">
              <el-input type="number" v-model="batch.min_ahref_traffic_score" />
            </div>
          </el-form-item>

          <el-form-item label="Minimum RefDomains">
            <div style="max-width: 200px">
              <el-input type="number" v-model="batch.filter_rd" />
            </div>
          </el-form-item>


          <div v-if="false">
            <el-form-item label="Minimum domain trust flow">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.filter_tf" />
              </div>
            </el-form-item>
            <el-form-item label="Minimum domain citation">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.filter_cf" />
              </div>
            </el-form-item>
            <el-form-item label="Minimum domain flow metrics ratio">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.filter_tf_cf" />
              </div>
            </el-form-item>
            <el-form-item label="Maximum domain flow metrics ratio">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.max_domain_flow_metrics_ratio" />
              </div>
            </el-form-item>

            <el-form-item label="Minimum SEM Rush volume score" v-if="false">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.filter_semrush_volume_score" />
              </div>
            </el-form-item>
            <el-form-item label="Minimum SEM Rush traffic score (US)" v-if="false">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.filter_semrush_traffic_score" />
              </div>
            </el-form-item>
            <el-form-item label="Minimum SEM Rush traffic score (UK)" v-if="false">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.min_semrush_uk_traffic_score" />
              </div>
            </el-form-item>
            <el-form-item label="Minimum SEM Rush traffic score (global)" v-if="false">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.min_semrush_global_traffic_score" />
              </div>
            </el-form-item>
            <el-form-item label="Minimum domain authority">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.min_domain_authority" />
              </div>
            </el-form-item>
            <el-form-item label="Maximum % nofollow links">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.filter_nofollow_percent" />
              </div>
            </el-form-item>
            <el-form-item label="Minimum host trust flow">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.filter_host_tf" />
              </div>
            </el-form-item>
            <el-form-item label="Minimum host citation" v-if="false">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.filter_host_cf" />
              </div>
            </el-form-item>
            <el-form-item label="Minimum host flow metrics ratio" v-if="false">
              <div style="max-width: 200px">
                <el-input type="number" v-model="batch.filter_host_tf_cf" />
              </div>
            </el-form-item>
          </div>

          <el-form-item label="Return homepages only">
            <el-switch v-model="batch.only_home" />
          </el-form-item>
          <el-form-item label="Discard previously found leads in Company">
            <el-switch v-model="batch.discard_company_site_links" />
          </el-form-item>
          <el-form-item label="Allow previously found links">
            <el-switch v-model="batch.not_discard_old_links" />
          </el-form-item>
          <el-form-item label="Discard links reviewed over 3 times">
            <el-switch v-model="batch.discard_leads_reviewed_3_times" />
          </el-form-item>
          <el-form-item label="Only links in top 1m Tranco list">
            <el-switch v-model="batch.only_in_top_1m" />
          </el-form-item>
          <el-form-item label="Allow same domain leads">
            <el-switch v-model="batch.allow_same_domain_site_links" />
          </el-form-item>
          <el-form-item label="Capture RLD data" v-if="false">
            <el-switch v-model="batch.capture_rld_data" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer>
        <div class="flex flex-row">
          <div v-if="mode !== 'create' && canDelete">
            <el-button type="danger" size="small" v-if="$store.getters['auth/hasPermission']('batch.delete')" icon="el-icon-delete" @click="deleteBatch">Permanently delete this batch</el-button>
          </div>
          <div class="ml-auto">
            <el-button
              v-if="mode === 'create'"
              type="danger"
              size="small"
              @click="()=>{ $refs['campaignForm'].resetFields(); }"
            >
              Reset
            </el-button>
            <el-button
              v-if="(mode === 'create' && $store.getters['auth/hasPermission']('batch.create')) || (mode !== 'create' && $store.getters['auth/hasPermission']('batch.update') && updatable)"
              size="small"
              icon="el-icon-edit-outline"
              type="success"
              @click="createBatch"
            >
              {{ mode === 'create' ? 'Create' : 'Edit' }} Batch
            </el-button>
          </div>
        </div>
      </template>
    </trieste-card>
  </div>
</template>

<script>
  import ProjectAndTargetForm from "@/components/views/global/ProjectAndTargetForm";
  import SiteRequirements from "@/components/views/links/SiteRequirements";
  import { elementDomainValidator } from "@/utils/helpers";
  export default {
    components: {SiteRequirements, ProjectAndTargetForm},
    props: {
      updatable: {
        type: Boolean,
        default: true
      }
    },
    data() {
      const keywordValidator = (rule, value, callback) => {
        if(!value || value.trim().length < 2) {
          return callback(new Error('Please enter at least one keyword'));
        }

        if (this.stop_words.length > 0) {
          const enteredWords = value.split('\n')
          for (const ew of enteredWords) {
            let cleanedWord = ew.trim().toLowerCase();
            if (this.stop_words.includes(cleanedWord)) {
              return callback(new Error(`The word/phrase '${ew}' is a stop word and not allowed.`))
            }
          }
        }

        return callback()
      }

      const urlValidator = (rule, value, callback) => {
        if(!value || value.trim().length < 2) {
          return callback(new Error('Please enter at least one URL'));
        }

        let lineCount = 1

        for(let url of value.trim().split('\n')) {
          url = url.trim()
          try {
            const parseUrl = new URL(url)
            if(!parseUrl || !parseUrl.origin) {
              return callback(new Error(`The URL on line ${lineCount} is not a valid URL.`))
            }
          } catch (e) {
            return callback(new Error(`The URL on line ${lineCount} is not a valid URL.`))
          }
          lineCount++
        }

        return callback()
      }

      const defaultTargetId = this.$route.query.target_id && this.$route.query.target_id / 1 || null

      let copyParams = {};
      if(this.$route.query.mode && this.$route.query.mode === 'copy' && this.$route.query.copy_params){
        if(this.$route.query.copy_params){
          try {
            console.log(this.$route.query.copy_params)
            copyParams = JSON.parse(this.$route.query.copy_params)
          }catch (e) {

          }
        }
      }

      console.log(copyParams)

      return {
        loading: false,
        loaded: false,
        mode: 'create',
        stop_words: [],
        show_campaign_select: false,
        campaigns: [],
        batch: {
          name: copyParams?.name ? 'Copy of ' + copyParams.name : '',
          default_site_target_id: defaultTargetId,
          content_language: 'en',
          content_country: 'GB',
          content_keywords: '',
          run_every_interval_days: null,
          batch_type: copyParams?.batch_type ? copyParams.batch_type : 'google_simple_query_v2',
          google_simple_query: '', //copyParams?.google_simple_query ? copyParams.google_simple_query  : '',
          html_data:  copyParams?.html_data ? copyParams.html_data  : '',
          domain: copyParams?.domain ? copyParams.domain: '',
          page_url: copyParams?.page_url ? copyParams.page_url: '',
          seid: copyParams?.seid ? copyParams.seid: 1,
          site_id: null,
          only_home: typeof copyParams.only_home === 'boolean' ? copyParams.only_home : true,
          discard_company_site_links: typeof copyParams.discard_company_site_links === 'boolean' ? copyParams.discard_company_site_links : true,
          not_discard_old_links: typeof copyParams.not_discard_old_links === 'boolean' ? copyParams.not_discard_old_links : false,
          discard_leads_reviewed_3_times: typeof copyParams.discard_leads_reviewed_3_times === 'boolean' ? copyParams.discard_leads_reviewed_3_times : false,
          discard_urls_over_1_year_old: typeof copyParams.discard_urls_over_1_year_old === 'boolean' ? copyParams.discard_urls_over_1_year_old : false, // cant find this in DB?
          allow_same_domain_site_links: typeof copyParams.allow_same_domain_site_links === 'boolean' ? copyParams.allow_same_domain_site_links : false,
          max_link_leads_per_domain: copyParams?.max_link_leads_per_domain ? copyParams.max_link_leads_per_domain: 1,
          capture_rld_data: typeof copyParams.capture_rld_data === 'boolean' ? copyParams.capture_rld_data : true,
          filter_tf: copyParams?.filter_tf ? copyParams.filter_tf: null,
          filter_cf: copyParams?.filter_cf ? copyParams.filter_cf: null,
          filter_tf_cf: copyParams?.filter_tf_cf ? copyParams.filter_tf_cf: null,
          max_domain_flow_metrics_ratio: copyParams?.max_domain_flow_metrics_ratio ? copyParams.max_domain_flow_metrics_ratio: null,
          filter_rd: copyParams?.filter_rd ? copyParams.filter_rd: null,
          filter_semrush_volume_score: copyParams?.filter_semrush_volume_score ? copyParams.filter_semrush_volume_score: null,
          filter_semrush_traffic_score: copyParams?.filter_semrush_traffic_score ? copyParams.filter_semrush_traffic_score: null,
          min_semrush_uk_traffic_score: copyParams?.min_semrush_uk_traffic_score ? copyParams.min_semrush_uk_traffic_score: null,
          min_semrush_global_traffic_score: copyParams?.min_semrush_global_traffic_score ? copyParams.min_semrush_global_traffic_score: null,
          min_domain_authority: copyParams?.min_domain_authority ? copyParams.min_domain_authority: null,
          filter_nofollow_percent: copyParams?.filter_nofollow_percent ? copyParams.filter_nofollow_percent: null,
          filter_ahrefs_domain_rating: copyParams?.filter_ahrefs_domain_rating ? copyParams.filter_ahrefs_domain_rating: null,
          min_ahref_traffic_score: copyParams?.min_ahref_traffic_score ? copyParams.min_ahref_traffic_score: null,
          filter_host_tf: copyParams?.filter_host_tf ? copyParams.filter_host_tf: null,
          filter_host_cf: copyParams?.filter_host_cf ? copyParams.filter_host_cf: null,
          filter_host_tf_cf: copyParams?.filter_host_tf_cf ? copyParams.filter_host_tf_cf: null,
          auto_accept: copyParams?.auto_accept ? copyParams.auto_accept: null,
          auto_accept_status: copyParams?.auto_accept_status ? copyParams.auto_accept_status: null,
          default_project_id: copyParams?.default_project_id ? copyParams.default_project_id: null,
          owner_id: this.$store.state.auth.user.id,
          only_in_top_1m: typeof copyParams.only_in_top_1m === 'boolean' ? copyParams.only_in_top_1m : false,
        },
        new_batch_rules: {
          // name: [
          //   {required: true, message: 'Please enter a batch name', trigger: 'change'},
          //   {required: true, message: 'Please enter a batch name', trigger: 'blur'},
          //   {required: true, message: 'Please enter a batch name', trigger: 'focus'}
          // ],
          campaign_id: [
            { required: true, message: 'Please select a campaign' }
          ],
          seid: [
            {required: true, message: 'Please select a search engine', trigger: 'change'},
            {required: true, message: 'Please select a search engine', trigger: 'blur'},
            {required: true, message: 'Please select a search engine', trigger: 'focus'}
          ],
          site_link: [
            {required: true, message: 'Please select a site', trigger: 'change'},
            {required: true, message: 'Please select a site', trigger: 'blur'},
            {required: true, message: 'Please select a site', trigger: 'focus'}
          ],
          google_simple_query: [
            { validator: keywordValidator }
          ],
          html_data: [
            { validator: urlValidator }
          ],
          domain: [
            { validator: elementDomainValidator, message: 'Please enter a valid domain' }
          ],
          page_url: [
            { type: 'url', message: 'Please enter a valid page url' }
          ],
          max_link_leads_per_domain: [
            { type: 'number' }
          ],
          owner_id: [
            { required: true, message: 'Please select a owner', trigger: 'blur' }
          ]
        },
        available_langs: [
          {
            label: "Afrikaans",
            value: "af"
          },
          {
            label: "Albanian",
            value: "sq"
          },
          {
            label: "Arabic",
            value: "ar"
          },
          {
            label: "Aragonese",
            value: "an"
          },
          {
            label: "Armenian",
            value: "hy"
          },
          {
            label: "Azeri",
            value: "az"
          },
          {
            label: "Basque",
            value: "eu"
          },
          {
            label: "Bengali",
            value: "bn"
          },
          {
            label: "Bosnian",
            value: "bs"
          },
          {
            label: "Breton",
            value: "br"
          },
          {
            label: "Bulgarian",
            value: "bg"
          },
          {
            label: "Catalan",
            value: "ca"
          },
          {
            label: "Chinese (Simplified)",
            value: "zh-СN"
          },
          {
            label: "Chinese (Traditional)",
            value: "zh-TW"
          },
          {
            label: "Croatian",
            value: "hr"
          },
          {
            label: "Czech",
            value: "cs"
          },
          {
            label: "Danish",
            value: "da"
          },
          {
            label: "Dutch",
            value: "nl"
          },
          {
            label: "English",
            value: "en"
          },
          {
            label: "Esperanto",
            value: "eo"
          },
          {
            label: "Estonian",
            value: "et"
          },
          {
            label: "Farsi",
            value: "fa"
          },
          {
            label: "Finnish",
            value: "fi"
          },
          {
            label: "French",
            value: "fr"
          },
          {
            label: "Galician",
            value: "gl"
          },
          {
            label: "Georgian",
            value: "ka"
          },
          {
            label: "German",
            value: "de"
          },
          {
            label: "Greek",
            value: "el"
          },
          {
            label: "Haitian",
            value: "ht"
          },
          {
            label: "Hebrew",
            value: "he"
          },
          {
            label: "Hindi",
            value: "hi"
          },
          {
            label: "Hungarian",
            value: "hu"
          },
          {
            label: "Icelandic",
            value: "is"
          },
          {
            label: "Indonesian",
            value: "id"
          },
          {
            label: "Italian",
            value: "it"
          },
          {
            label: "Japanese",
            value: "ja"
          },
          {
            label: "Javanese",
            value: "jv"
          },
          {
            label: "Kazakh",
            value: "kk"
          },
          {
            label: "Korean",
            value: "ko"
          },
          {
            label: "Latin",
            value: "la"
          },
          {
            label: "Latvian",
            value: "lv"
          },
          {
            label: "Lithuanian",
            value: "lt"
          },
          {
            label: "Luxembourgish",
            value: "lb"
          },
          {
            label: "Macedonian",
            value: "mk"
          },
          {
            label: "Malagasy",
            value: "mg"
          },
          {
            label: "Malay",
            value: "ms"
          },
          {
            label: "Malayam",
            value: "ml"
          },
          {
            label: "Marathi",
            value: "mr"
          },
          {
            label: "Norwegian",
            value: "no"
          },
          {
            label: "Norwegian (Nynorsk)",
            value: "nn"
          },
          {
            label: "Occitan",
            value: "oc"
          },
          {
            label: "Polish",
            value: "pl"
          },
          {
            label: "Portuguese",
            value: "pt"
          },
          {
            label: "Romanian",
            value: "ro"
          },
          {
            label: "Russian",
            value: "ru"
          },
          {
            label: "Serbian",
            value: "sr"
          },
          {
            label: "Slovak",
            value: "sk"
          },
          {
            label: "Slovenian",
            value: "sl"
          },
          {
            label: "Spanish",
            value: "es"
          },
          {
            label: "Swahili",
            value: "sw"
          },
          {
            label: "Swedish",
            value: "sv"
          },
          {
            label: "Tagalog",
            value: "tl"
          },
          {
            label: "Tamil",
            value: "ta"
          },
          {
            label: "Telugu",
            value: "te"
          },
          {
            label: "Thai",
            value: "th"
          },
          {
            label: "Turkish",
            value: "tr"
          },
          {
            label: "Ukrainian",
            value: "uk"
          },
          {
            label: "Urdu",
            value: "ur"
          },
          {
            label: "Vietnamese",
            value: "vi"
          },
          {
            label: "Volapük",
            value: "vo"
          },
          {
            label: "Welsh",
            value: "cy"
          }
        ],
        available_countries: [
          {
            label: "Afghanistan",
            value: "AF"
          },
          {
            label: "Albania",
            value: "AL"
          },
          {
            label: "Antarctica",
            value: "AQ"
          },
          {
            label: "Algeria",
            value: "DZ"
          },
          {
            label: "American Samoa",
            value: "AS"
          },
          {
            label: "Andorra",
            value: "AD"
          },
          {
            label: "Angola",
            value: "AO"
          },
          {
            label: "Antigua and Barbuda",
            value: "AG"
          },
          {
            label: "Azerbaijan",
            value: "AZ"
          },
          {
            label: "Argentina",
            value: "AR"
          },
          {
            label: "Australia",
            value: "AU"
          },
          {
            label: "Austria",
            value: "AT"
          },
          {
            label: "The Bahamas",
            value: "BS"
          },
          {
            label: "Bahrain",
            value: "BH"
          },
          {
            label: "Bangladesh",
            value: "BD"
          },
          {
            label: "Armenia",
            value: "AM"
          },
          {
            label: "Barbados",
            value: "BB"
          },
          {
            label: "Belgium",
            value: "BE"
          },
          {
            label: "Bermuda",
            value: "BM"
          },
          {
            label: "Bhutan",
            value: "BT"
          },
          {
            label: "Bolivia",
            value: "BO"
          },
          {
            label: "Bosnia and Herzegovina",
            value: "BA"
          },
          {
            label: "Botswana",
            value: "BW"
          },
          {
            label: "Brazil",
            value: "BR"
          },
          {
            label: "Belize",
            value: "BZ"
          },
          {
            label: "British Indian Ocean Territory",
            value: "IO"
          },
          {
            label: "Solomon Islands",
            value: "SB"
          },
          {
            label: "British Virgin Islands",
            value: "VG"
          },
          {
            label: "Brunei",
            value: "BN"
          },
          {
            label: "Bulgaria",
            value: "BG"
          },
          {
            label: "Myanmar (Burma)",
            value: "MM"
          },
          {
            label: "Burundi",
            value: "BI"
          },
          {
            label: "Cambodia",
            value: "KH"
          },
          {
            label: "Cameroon",
            value: "CM"
          },
          {
            label: "Canada",
            value: "CA"
          },
          {
            label: "Cabo Verde",
            value: "CV"
          },
          {
            label: "Cayman Islands",
            value: "KY"
          },
          {
            label: "Central African Republic",
            value: "CF"
          },
          {
            label: "Sri Lanka",
            value: "LK"
          },
          {
            label: "Chad",
            value: "TD"
          },
          {
            label: "Chile",
            value: "CL"
          },
          {
            label: "China",
            value: "CN"
          },
          {
            label: "Taiwan",
            value: "TW"
          },
          {
            label: "Christmas Island",
            value: "CX"
          },
          {
            label: "Cocos (Keeling) Islands",
            value: "CC"
          },
          {
            label: "Colombia",
            value: "CO"
          },
          {
            label: "Comoros",
            value: "KM"
          },
          {
            label: "Mayotte",
            value: "YT"
          },
          {
            label: "Republic of the Congo",
            value: "CG"
          },
          {
            label: "Democratic Republic of the Congo",
            value: "CD"
          },
          {
            label: "Cook Islands",
            value: "CK"
          },
          {
            label: "Costa Rica",
            value: "CR"
          },
          {
            label: "Croatia",
            value: "HR"
          },
          {
            label: "Cyprus",
            value: "CY"
          },
          {
            label: "Czechia",
            value: "CZ"
          },
          {
            label: "Benin",
            value: "BJ"
          },
          {
            label: "Denmark",
            value: "DK"
          },
          {
            label: "Dominica",
            value: "DM"
          },
          {
            label: "Dominican Republic",
            value: "DO"
          },
          {
            label: "Ecuador",
            value: "EC"
          },
          {
            label: "El Salvador",
            value: "SV"
          },
          {
            label: "Equatorial Guinea",
            value: "GQ"
          },
          {
            label: "Ethiopia",
            value: "ET"
          },
          {
            label: "Eritrea",
            value: "ER"
          },
          {
            label: "Estonia",
            value: "EE"
          },
          {
            label: "Faroe Islands",
            value: "FO"
          },
          {
            label: "Falkland Islands (Islas Malvinas)",
            value: "FK"
          },
          {
            label: "Fiji",
            value: "FJ"
          },
          {
            label: "Finland",
            value: "FI"
          },
          {
            label: "France",
            value: "FR"
          },
          {
            label: "French Guiana",
            value: "GF"
          },
          {
            label: "French Polynesia",
            value: "PF"
          },
          {
            label: "Djibouti",
            value: "DJ"
          },
          {
            label: "Gabon",
            value: "GA"
          },
          {
            label: "Georgia",
            value: "GE"
          },
          {
            label: "The Gambia",
            value: "GM"
          },
          {
            label: "Palestine",
            value: "PS"
          },
          {
            label: "Germany",
            value: "DE"
          },
          {
            label: "Ghana",
            value: "GH"
          },
          {
            label: "Gibraltar",
            value: "GI"
          },
          {
            label: "Kiribati",
            value: "KI"
          },
          {
            label: "Greece",
            value: "GR"
          },
          {
            label: "Greenland",
            value: "GL"
          },
          {
            label: "Grenada",
            value: "GD"
          },
          {
            label: "Guadeloupe",
            value: "GP"
          },
          {
            label: "Guam",
            value: "GU"
          },
          {
            label: "Guatemala",
            value: "GT"
          },
          {
            label: "Guinea",
            value: "GN"
          },
          {
            label: "Guyana",
            value: "GY"
          },
          {
            label: "Haiti",
            value: "HT"
          },
          {
            label: "Vatican City",
            value: "VA"
          },
          {
            label: "Honduras",
            value: "HN"
          },
          {
            label: "Hong Kong",
            value: "HK"
          },
          {
            label: "Hungary",
            value: "HU"
          },
          {
            label: "Iceland",
            value: "IS"
          },
          {
            label: "India",
            value: "IN"
          },
          {
            label: "Indonesia",
            value: "ID"
          },
          {
            label: "Iraq",
            value: "IQ"
          },
          {
            label: "Ireland",
            value: "IE"
          },
          {
            label: "Israel",
            value: "IL"
          },
          {
            label: "Italy",
            value: "IT"
          },
          {
            label: "Cote d'Ivoire",
            value: "CI"
          },
          {
            label: "Jamaica",
            value: "JM"
          },
          {
            label: "Japan",
            value: "JP"
          },
          {
            label: "Kazakhstan",
            value: "KZ"
          },
          {
            label: "Jordan",
            value: "JO"
          },
          {
            label: "Kenya",
            value: "KE"
          },
          {
            label: "South Korea",
            value: "KR"
          },
          {
            label: "Kuwait",
            value: "KW"
          },
          {
            label: "Kyrgyzstan",
            value: "KG"
          },
          {
            label: "Laos",
            value: "LA"
          },
          {
            label: "Lebanon",
            value: "LB"
          },
          {
            label: "Lesotho",
            value: "LS"
          },
          {
            label: "Latvia",
            value: "LV"
          },
          {
            label: "Liberia",
            value: "LR"
          },
          {
            label: "Libya",
            value: "LY"
          },
          {
            label: "Liechtenstein",
            value: "LI"
          },
          {
            label: "Lithuania",
            value: "LT"
          },
          {
            label: "Luxembourg",
            value: "LU"
          },
          {
            label: "Macao",
            value: "MO"
          },
          {
            label: "Madagascar",
            value: "MG"
          },
          {
            label: "Malawi",
            value: "MW"
          },
          {
            label: "Malaysia",
            value: "MY"
          },
          {
            label: "Maldives",
            value: "MV"
          },
          {
            label: "Mali",
            value: "ML"
          },
          {
            label: "Malta",
            value: "MT"
          },
          {
            label: "Martinique",
            value: "MQ"
          },
          {
            label: "Mauritania",
            value: "MR"
          },
          {
            label: "Mauritius",
            value: "MU"
          },
          {
            label: "Mexico",
            value: "MX"
          },
          {
            label: "Monaco",
            value: "MC"
          },
          {
            label: "Mongolia",
            value: "MN"
          },
          {
            label: "Moldova",
            value: "MD"
          },
          {
            label: "Montenegro",
            value: "ME"
          },
          {
            label: "Montserrat",
            value: "MS"
          },
          {
            label: "Morocco",
            value: "MA"
          },
          {
            label: "Mozambique",
            value: "MZ"
          },
          {
            label: "Oman",
            value: "OM"
          },
          {
            label: "Namibia",
            value: "NA"
          },
          {
            label: "Nauru",
            value: "NR"
          },
          {
            label: "Nepal",
            value: "NP"
          },
          {
            label: "Netherlands",
            value: "NL"
          },
          {
            label: "Curacao",
            value: "CW"
          },
          {
            label: "Aruba",
            value: "AW"
          },
          {
            label: "Sint Maarten",
            value: "SX"
          },
          {
            label: "Caribbean Netherlands",
            value: "BQ"
          },
          {
            label: "New Caledonia",
            value: "NC"
          },
          {
            label: "Vanuatu",
            value: "VU"
          },
          {
            label: "New Zealand",
            value: "NZ"
          },
          {
            label: "Nicaragua",
            value: "NI"
          },
          {
            label: "Niger",
            value: "NE"
          },
          {
            label: "Nigeria",
            value: "NG"
          },
          {
            label: "Niue",
            value: "NU"
          },
          {
            label: "Norfolk Island",
            value: "NF"
          },
          {
            label: "Norway",
            value: "NO"
          },
          {
            label: "Northern Mariana Islands",
            value: "MP"
          },
          {
            label: "Micronesia",
            value: "FM"
          },
          {
            label: "Marshall Islands",
            value: "MH"
          },
          {
            label: "Palau",
            value: "PW"
          },
          {
            label: "Pakistan",
            value: "PK"
          },
          {
            label: "Panama",
            value: "PA"
          },
          {
            label: "Papua New Guinea",
            value: "PG"
          },
          {
            label: "Paraguay",
            value: "PY"
          },
          {
            label: "Peru",
            value: "PE"
          },
          {
            label: "Philippines",
            value: "PH"
          },
          {
            label: "Pitcairn Islands",
            value: "PN"
          },
          {
            label: "Poland",
            value: "PL"
          },
          {
            label: "Portugal",
            value: "PT"
          },
          {
            label: "Guinea-Bissau",
            value: "GW"
          },
          {
            label: "Timor-Leste",
            value: "TL"
          },
          {
            label: "Puerto Rico",
            value: "PR"
          },
          {
            label: "Qatar",
            value: "QA"
          },
          {
            label: "Reunion",
            value: "RE"
          },
          {
            label: "Romania",
            value: "RO"
          },
          {
            label: "Rwanda",
            value: "RW"
          },
          {
            label: "Saint Helena, Ascension and Tristan da Cunha",
            value: "SH"
          },
          {
            label: "Saint Kitts and Nevis",
            value: "KN"
          },
          {
            label: "Anguilla",
            value: "AI"
          },
          {
            label: "Saint Lucia",
            value: "LC"
          },
          {
            label: "Saint Pierre and Miquelon",
            value: "PM"
          },
          {
            label: "Saint Vincent and the Grenadines",
            value: "VC"
          },
          {
            label: "San Marino",
            value: "SM"
          },
          {
            label: "Sao Tome and Principe",
            value: "ST"
          },
          {
            label: "Saudi Arabia",
            value: "SA"
          },
          {
            label: "Senegal",
            value: "SN"
          },
          {
            label: "Serbia",
            value: "RS"
          },
          {
            label: "Seychelles",
            value: "SC"
          },
          {
            label: "Sierra Leone",
            value: "SL"
          },
          {
            label: "Singapore",
            value: "SG"
          },
          {
            label: "Slovakia",
            value: "SK"
          },
          {
            label: "Vietnam",
            value: "VN"
          },
          {
            label: "Slovenia",
            value: "SI"
          },
          {
            label: "Somalia",
            value: "SO"
          },
          {
            label: "South Africa",
            value: "ZA"
          },
          {
            label: "Zimbabwe",
            value: "ZW"
          },
          {
            label: "Spain",
            value: "ES"
          },
          {
            label: "South Sudan",
            value: "SS"
          },
          {
            label: "Sudan",
            value: "SD"
          },
          {
            label: "Suriname",
            value: "SR"
          },
          {
            label: "Eswatini",
            value: "SZ"
          },
          {
            label: "Sweden",
            value: "SE"
          },
          {
            label: "Switzerland",
            value: "CH"
          },
          {
            label: "Tajikistan",
            value: "TJ"
          },
          {
            label: "Thailand",
            value: "TH"
          },
          {
            label: "Togo",
            value: "TG"
          },
          {
            label: "Tokelau",
            value: "TK"
          },
          {
            label: "Tonga",
            value: "TO"
          },
          {
            label: "Trinidad and Tobago",
            value: "TT"
          },
          {
            label: "United Arab Emirates",
            value: "AE"
          },
          {
            label: "Tunisia",
            value: "TN"
          },
          {
            label: "Turkiye",
            value: "TR"
          },
          {
            label: "Turkmenistan",
            value: "TM"
          },
          {
            label: "Turks and Caicos Islands",
            value: "TC"
          },
          {
            label: "Tuvalu",
            value: "TV"
          },
          {
            label: "Uganda",
            value: "UG"
          },
          {
            label: "Ukraine",
            value: "UA"
          },
          {
            label: "North Macedonia",
            value: "MK"
          },
          {
            label: "Egypt",
            value: "EG"
          },
          {
            label: "United Kingdom",
            value: "GB"
          },
          {
            label: "Guernsey",
            value: "GG"
          },
          {
            label: "Jersey",
            value: "JE"
          },
          {
            label: "Isle of Man",
            value: "IM"
          },
          {
            label: "Tanzania",
            value: "TZ"
          },
          {
            label: "United States",
            value: "US"
          },
          {
            label: "U.S. Virgin Islands",
            value: "VI"
          },
          {
            label: "Burkina Faso",
            value: "BF"
          },
          {
            label: "Uruguay",
            value: "UY"
          },
          {
            label: "Uzbekistan",
            value: "UZ"
          },
          {
            label: "Venezuela",
            value: "VE"
          },
          {
            label: "Wallis and Futuna",
            value: "WF"
          },
          {
            label: "Samoa",
            value: "WS"
          },
          {
            label: "Yemen",
            value: "YE"
          },
          {
            label: "Zambia",
            value: "ZM"
          },
          {
            label: "Kosovo",
            value: "XK"
          }
        ],
        available_search_engines: [
          {label: 'International', value: 1},
          {label: 'Denmark', value: 12},
          {label: 'France', value: 4},
          {label: 'Germany', value: 2},
          {label: 'Italy', value: 17},
          {label: 'Republic of Ireland', value: 36},
          {label: 'Spain', value: 7},
          {label: 'UK', value: 3},
          {label: 'Canada', value: 6},
          {label: 'Australia', value: 19},
          {label: 'New Zealand', value: 20},
          {label: 'Sweden', value: 9},
          {label: 'Norway', value: 13},
          {label: 'Finland', value: 14},
          {label: 'Netherlands', value: 8}
        ],
        company_users: []
      }
    },
    created() {
      if(this.$route.params.batchId) {
        if(!this.$store.getters['auth/hasPermission']('batch.read')) {
          this.$message.error('You do not have permissions to view batches');
          this.$router.push('/');
        }
        this.setBatchFromState()
      } else {
        if(!this.$store.getters['auth/hasPermission']('batch.create')) {
          this.$message.error('You do not have permissions to view campaigns.');
          this.$router.push('/');
        }
        this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
          if(this.$route.params.campaignId) {
            this.$store.dispatch('campaign/getCampaign', this.$route.params).then((campaign) => {
              this.$store.dispatch('site/getSite', {siteId: campaign.site_id}).then((site) => {
                this.$store.dispatch('client/getClient', {clientId: site.client_id}).then((client) => {
                  this.setBreadcrumbs(company, client, site, campaign)
                  this.loadAdditionalResources()

                  // If we are in batch copy mode dont pull in defaults
                  if(!this.$route.query.mode && !this.$route.query.mode !== 'copy'){
                    setTimeout(this.mergeWithSiteDefaults, 600)
                  }
                })
              })
            })
          } else {
            this.$store.dispatch('site/getSite', this.$route.params).then((site) => {
              this.$store.dispatch('client/getClient', {clientId: site.client_id}).then((client) => {
                this.show_campaign_select = true
                this.setBreadcrumbs(company, client, site)
                this.loadAdditionalResources()
                setTimeout(this.mergeWithSiteDefaults, 600)
              })
            })
          }
        })
      }
    },
    computed: {
      canSetDefaultStatus() {
        return ['domain', 'single_page', 'html_data'].includes(this.batch.batch_type)
      },
      canUpdateBatch() {
        return (this.batch.id && this.batch.owner_id === this.$store.state.auth.user.id)
      },
      /*
      allowedSiteTargets () {
        if(this.batch.default_project_id) {
          const project = this.projects.find(i => i.id === this.batch.default_project_id)
          const allowedTargets = []
          if(project) {
            project.site_targets.forEach(pst => {
              if(pst.site_target && !allowedTargets.includes(pst.site_target.id)) {
                allowedTargets.push(pst.site_target.id);
              }
            })
            return this.site_targets.filter(st => allowedTargets.includes(st.id))
          }
        }

        return this.site_targets;
      },
      */
      canDelete() {
        const valuewner = this.$store.state.auth.user.id === this.batch.owner_id;
        if (!valuewner && this.$store.getters['auth/hasPermission']('other.batch.delete')) {
          return true
        } else if (valuewner && this.$store.getters['auth/hasPermission']('batch.update')) {
          return true
        }

        return false
      },
    },
    methods: {
      getBatchParams(){
        return  {
          name: this.batch.name,
          default_site_target_id: this.batch.default_site_target_id,
          campaign_id: this.batch.campaign_id,
          company_id: this.batch.company_id,
          batch_type: this.batch.batch_type,
          google_simple_query: this.batch.google_simple_query  ,
          html_data:  this.batch.html_data  ,
          domain: this.batch.domain,
          page_url: this.batch.page_url,
          seid: this.batch.seid,
          site_id: this.batch.site_id,
          only_home: this.batch.only_home,
          discard_company_site_links: this.batch.discard_company_site_links,
          not_discard_old_links: this.batch.not_discard_old_links,
          discard_leads_reviewed_3_times: this.batch.discard_leads_reviewed_3_times,
          discard_urls_over_1_year_old: this.batch.discard_urls_over_1_year_old, // cant find this in DB?
          allow_same_domain_site_links: this.batch.allow_same_domain_site_links,
          max_link_leads_per_domain: this.batch.max_link_leads_per_domain,
          capture_rld_data: this.batch.capture_rld_data,
          filter_tf: this.batch.filter_tf,
          filter_cf: this.batch.filter_cf,
          filter_tf_cf: this.batch.filter_tf_cf,
          max_domain_flow_metrics_ratio: this.batch.max_domain_flow_metrics_ratio,
          filter_rd: this.batch.filter_rd,
          filter_semrush_volume_score: this.batch.filter_semrush_volume_score,
          filter_semrush_traffic_score: this.batch.filter_semrush_traffic_score,
          min_semrush_uk_traffic_score: this.batch.min_semrush_uk_traffic_score,
          min_semrush_global_traffic_score: this.batch.min_semrush_global_traffic_score,
          min_domain_authority: this.batch.min_domain_authority,
          filter_nofollow_percent: this.batch.filter_nofollow_percent,
          filter_ahrefs_domain_rating: this.batch.filter_ahrefs_domain_rating,
          min_ahref_traffic_score: this.batch.min_ahref_traffic_score,
          filter_host_tf: this.batch.filter_host_tf,
          filter_host_cf: this.batch.filter_host_cf,
          filter_host_tf_cf: this.batch.filter_host_tf_cf,
          auto_accept: this.batch.auto_accept,
          auto_accept_status: this.batch.auto_accept_status,
          default_project_id: this.batch.default_project_id,
          only_in_top_1m: this.batch.only_in_top_1m,
        }
      },
      setBreadcrumbs(company, client, site, campaign) {
        const breadcrumbs = [
          {
            path: '/',
            label: company.name
          },

          {
            path: `/company/${company.id}/clients`,
            label: 'clients'
          },
          {
            path: `/company/${company.id}/clients/${client.id}`,
            label: client.name
          },
          {
            path: `/company/${company.id}/clients/${client.id}?tab=sites`,
            label: 'sites'
          },
          {
            path: `/company/${company.id}/sites/${site.id}`,
            label: site.domain
          }
      ]

      if(campaign) {
        breadcrumbs.push(
          {
            path: `/company/${company.id}/sites/${site.id}?tab=campaigns`,
            label: 'campaigns'
          },
          {
            label: this.$store.state.campaign.campaign.name,
            path: `/company/${company.id}/campaigns/${campaign.id}`,
          }
        )
      }

      breadcrumbs.push({
        label: 'Create Batch'
      })

      this.$store.commit('global/setBreadcrumbs', breadcrumbs)
      },
      setBatchFromState() {
        this.$store.dispatch('batch/getBatch', this.$route.params).then(() => {
          this.mode = 'edit';
          this.batch = Object.assign({}, this.$store.state.batch.batch);
          this.loadAdditionalResources()
        })
      },
      loadAdditionalResources() {
        this.loading = true;
        const requests = [
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/sites/${this.$store.state.site.site.id}/stop-words`),
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/sites/${this.$store.state.site.site.id}/targets`, { params: { all: true } }),
        ];
        if(this.show_campaign_select) {
          requests.push(this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/campaigns`, {
            params: {
              all: true,
              site_id: this.$store.state.site.site.id
            }
          }))
        }
        this.$store.dispatch('users/loadAll', {}).then(r => {
          this.company_users = r
        }).finally(() => {
          this.$http.all(requests).then(this.$http.spread((stopWords, siteTargets, campaigns) => {
            this.stop_words = stopWords.data.data
            //this.projects = projects.data.data
            this.site_targets = []
            const foundTargetIds = []
            /*this.projects.forEach(project => {
              project.site_targets.forEach(st => {
                if(st.site_target) {
                  if (!foundTargetIds.includes(st.site_target.id)) {
                    foundTargetIds.push(st.site_target.id)
                    this.site_targets.push({
                      ...st.site_target,
                      project_id: project.id
                    })
                  }
                }
              })
            })*/
            if(campaigns) {
              this.campaigns = campaigns.data.data;
              if(!this.batch.campaign_id && this.campaigns.length) {
                this.batch.campaign_id = this.campaigns[0].id
              }
            }
          }))
            .finally(() => {
              this.loading = false;
              this.loaded = true;
            })
        })
      },
      createBatch() {
        this.$refs.projectAndTargetForm.validate((pandtValid) => {
          this.$refs.campaignForm.validate((valid) => {
            //console.log('And here')
            if(valid && pandtValid) {
              this.loading = true;
              let URL = `/v1/companies/${this.$route.params.companyId}/batches`;

              let actionType = this.$http.post;

              const Entity = Object.assign({}, this.batch, {
                campaign_id: this.show_campaign_select ? this.batch.campaign_id : this.$route.params.campaignId,
                site_id: this.$store.state.site.site.id
              });
              if(Entity.google_simple_query) {
                Entity.google_simple_query = Entity.google_simple_query.trim().split("\n");
              }

              if(this.batch.batch_type === 'content_analysis') {
                Entity.content_keywords = Entity.content_keywords.trim().split("\n");
              }

              if(this.batch.batch_type === 'html_data') {
                Entity.html_data = Entity.html_data.trim().split("\n");
              }

              if(this.mode !== 'create') {
                actionType = this.$http.put;
                URL += `/${this.$route.params.batchId}`;
              }

              actionType(URL, Entity).then((r) => {
                this.$message.success('Successfully '+(this.mode==='create' ? 'created' : 'updated')+' the batch');
                if(this.mode === 'create') {
                  this.$refs.campaignForm.resetFields();
                  this.$router.push(`/company/${this.$route.params.companyId}/campaigns/${Entity.campaign_id}`);
                } else {
                  this.setBatchFromState()
                }
              }).catch((e) => {
                this.$message.error('An error occurred while '+(this.mode==='create' ? 'creating' : 'updating')+' the batch.');
                console.error(e)
              }).finally(() => {
                this.loading = false;
              })
            } else {
              this.$message.error('Sorry, one or more errors were found in the form.')
              this.$scrollTo(this.$refs.top);
            }
          });
        })
      },
      deleteBatch() {
        this.$confirm(`Are you sure you want to delete '${this.batch.name}'? This will delete ALL site links`, 'are you sure?', {
          confirmButtonClass: 'el-button--danger'
        })
          .then(() => {
            this.$http.delete(`/v1/companies/${this.$store.state.company.company.id}/batches/${this.batch.id}`)
              .then(() => {
                this.$message.success('Successfully deleted the batch');
                this.$router.push(`/company/${this.$route.params.companyId}/campaigns/${this.$store.state.campaign.campaign.id}`)
              })
              .catch(() => {
                this.$message.error('An error occurred deleting that batch. Please try again later.')
              })
          })
          .catch((e) => { console.error(e)/* do nothing */})
      },
      mergeWithSiteDefaults() {
        const state = this.$store.state
        this.batch.only_home = state.site.site.only_home;
        this.batch.default_project_id = state.site.site.default_project_id;
        this.batch.discard_company_site_links = state.site.site.discard_company_site_links;
        this.batch.not_discard_old_links = state.site.site.not_discard_old_links;
        this.batch.discard_leads_reviewed_3_times = state.site.site.discard_leads_reviewed_3_times;
        this.batch.discard_urls_over_1_year_old = state.site.site.discard_urls_over_1_year_old;
        this.batch.allow_same_domain_site_links = state.site.site.allow_same_domain_site_links;
        this.batch.max_link_leads_per_domain = state.site.site.max_link_leads_per_domain;
        this.batch.capture_rld_data = state.site.site.capture_rld_data;
        this.batch.filter_tf = state.site.site.filter_tf;
        this.batch.filter_cf = state.site.site.filter_cf;
        this.batch.filter_tf_cf = state.site.site.filter_tf_cf;
        this.batch.max_domain_flow_metrics_ratio = state.site.site.max_domain_flow_metrics_ratio;
        this.batch.filter_rd = state.site.site.filter_rd;
        this.batch.filter_semrush_volume_score = state.site.site.filter_semrush_volume_score;
        this.batch.filter_semrush_traffic_score = state.site.site.filter_semrush_traffic_score;
        this.batch.min_semrush_uk_traffic_score = state.site.site.min_semrush_uk_traffic_score;
        this.batch.min_domain_authority = state.site.site.min_domain_authority;
        this.batch.filter_nofollow_percent = state.site.site.filter_nofollow_percent;
        this.batch.filter_ahrefs_domain_rating = state.site.site.filter_ahrefs_domain_rating;
        this.batch.filter_host_tf = state.site.site.filter_host_tf;
        this.batch.filter_host_cf = state.site.site.filter_host_cf;
        this.batch.filter_host_tf_cf = state.site.site.filter_host_tf_cf;
        this.batch.min_ahref_traffic_score = state.site.site.ahref_traffic_score;

        // check if we have a default project.
        const defaultProject = state.campaign.campaign.default_project_id || state.site.site.default_project_id || null
        this.batch.default_project_id = defaultProject
      },
      checkCustomUrlListValuesOnPaste (e) {
        setTimeout(() => {
          const matches = [...(this.batch.html_data || '').matchAll(/((?:https?)?:\/\/(?:www\.)?[a-zA-Z0-9_\-\.\/]+[a-zA-Z0-9\/])/g)]
          this.batch.html_data = matches.map(m => m[1]).join('\n')
        }, 50)
      }
    }
  }
</script>

<style lang="scss" scoped>
.el-select-dropdown__item {
  height: auto;
}
</style>
