<template>
  <div v-loading="loading">
    <trieste-card title="Check for link" :collapsable="true" :collapsed="collapsed">
      <div class="p-4">
        <trieste-info-column label="Target Link" :is-compact="true" :is-multi-line="true"  :value="overwrite_form.target_url" />
        <trieste-info-column label="Anchor text" :is-compact="true" :is-multi-line="true" :value="overwrite_form.anchor_text" />
        <trieste-info-column label="Lead link valid" :is-multi-line="true" :is-compact="true" :value="stats.lead_link_found" error :success="stats.lead_link_found"  />
        <trieste-info-column label="Lead link noindex" :is-multi-line="true" :is-compact="true" :value="stats.url_no_index" error :success="!stats.url_no_index"  />
        <trieste-info-column label="Lead link status" :is-multi-line="true" :is-compact="true" :value="stats.lead_link_status" error :success="stats.lead_link_status === 200"  />
        <trieste-info-column label="Lead robots meta" :is-multi-line="true" :is-compact="true" :value="stats.lead_meta_robots" />
        <trieste-info-column label="Target link found" :is-multi-line="true" :is-compact="true" :value="stats.target_link_found" error :success="stats.target_link_found"  />
        <trieste-info-column label="Target link nofollow" :is-multi-line="true" :is-compact="true" :value="stats.link_no_follow" error :success="!stats.link_no_follow"  />
        <trieste-info-column label="Anchor text found" :is-multi-line="true" :is-compact="true" :value="stats.anchor_text_found" error :success="stats.anchor_text_found"  />
        <trieste-info-column label="Anchor text matches link" :is-multi-line="true" :is-compact="true" :value="stats.anchor_text_match_url" error :success="stats.anchor_text_match_url"  />
        <trieste-info-column label="Similar target link found" :is-multi-line="true" :is-compact="true" :value="stats.similar_links_found" error :success="stats.similar_links_found"  />
        <trieste-info-column label="Target link rel" :is-multi-line="true" :is-compact="true" :value="stats.target_link_rel"  />
        <trieste-info-column label="Check date" :is-multi-line="true" :is-compact="true" :value="stats.check_date" date  />
        <trieste-info-column label="Updated at" :is-multi-line="true" :is-compact="true" :value="stats.updated_at" date  />
        <trieste-info-column label="Updated by" :is-multi-line="true" :is-compact="true" :value="`${stats.updated_by_user.firstname} ${stats.updated_by_user.lastname}`" v-if="stats.updated_by_user" />
      </div>
      <template v-slot:footer>
        <div class="text-right">
          <el-button type="success" icon="el-icon-edit-outline" @click="beforeRefreshData" size="small">Update data</el-button>
        </div>
      </template>
    </trieste-card>
    <trieste-dialog title="Overwrite values" :show.sync="show_overwrite_form">
      <div class="p-4">
        <el-form :data="overwrite_form">
          <el-form-item label="Lead Url" prop="base_url" v-if="false">
            <el-input v-model="overwrite_form.base_url" class="w-full" />
          </el-form-item>
          <el-form-item label="Target Url" prop="target_url">
            <el-input v-model="overwrite_form.target_url" class="w-full" />
          </el-form-item>
          <el-form-item label="Anchor text" prop="anchor_text">
            <el-input v-model="overwrite_form.anchor_text" class="w-full" />
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right>
        <el-button @click="resetOverwriteForm" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button
          type="success"
          icon="el-icon-edit-outline" @click="getData" size="small">Update</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      collapsed: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        stats: this.value.link_monitoring || {},
        loading: false,
        overwrite_form: {
          target_url: (this.value.link_monitoring ? this.value.link_monitoring.target_link : (this.value.SiteTarget ? this.value.SiteTarget.to_url : null)),
          base_url: (this.value.link_monitoring ? this.value.link_monitoring.base_url : null) || this.value.from_url,
          anchor_text: (this.value.link_monitoring ? this.value.link_monitoring.target_anchor : (this.value.ContentItem ? this.value.ContentItem.anchor : null))
        },
        show_overwrite_form: false
      }
    },
    methods: {
      resetOverwriteForm() {
        this.overwrite_form = {
          target_url: (this.value.link_monitoring ? this.value.link_monitoring.target_url : null) || this.value.SiteTarget ? this.value.SiteTarget.to_url : null,
          base_url: (this.value.link_monitoring ? this.value.link_monitoring.base_url : null) || this.value.from_url,
          anchor_text: (this.value.link_monitoring ? this.value.link_monitoring.target_anchor : null) || (this.value.ContentItem ? this.value.ContentItem.anchor : null)
        }
        this.show_overwrite_form = false
      },
      beforeRefreshData() {
        // if(this.$store.getters['auth/hasPermission']('lead.monitor.customise')) {
          this.show_overwrite_form = true
        // } else {
        // this.getData();
        // }
      },
      getData() {
        this.loading = true
        const path = `/v1/companies/${this.$store.state.company.company.id}/links/${this.value.id}/monitor`
        this.$http.get(path, {
          params: this.overwrite_form
        }).then((r) => {
          this.stats = r.data.data
          this.$emit('update:collapsed', false)
          //this.resetOverwriteForm()
          this.show_overwrite_form = false
        })
        .catch(() => {
          this.$message.error('Failed to get the latest stats.')
        })
        .finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>
