<template>
  <div v-loading="loading">

    <div class="page-title">
      <h1>
        <span>{{ $store.state.emailAccount.account.email }}
          <span class="faded-text">
            <span class="faded-strike">/</span>
            Email account
            <span class="text-red-600 font-semibold" v-if="$store.state.emailAccount.account.status === 'failed'">
              <span class="faded-strike">/</span>
              Failed
            </span>
          </span>
        </span>
      </h1>
      <div class="controls">
     <!--
        <el-button type="info" size="mini"
                   v-if="$store.getters['auth/hasPermission']('emailaccount.update') && loaded">
          Placement
        </el-button>
        <el-button  size="mini"
                   v-if="$store.getters['auth/hasPermission']('emailaccount.update') && loaded">
          Import
        </el-button>
        -->
        <el-button  icon="el-icon-lock" size="mini" @click="openPasswordReveal" v-if="$store.getters['auth/hasPermission']('emailaccount.update') && loaded">
          View Password
        </el-button>
        <el-button type="success" icon="el-icon-edit" size="mini" @click="show_settings = true" v-if="$store.getters['auth/hasPermission']('emailaccount.update') && loaded">Edit</el-button>
        <el-button type="primary" size="mini" icon="el-icon-refresh-left" @click="resetEmail" v-if="account.status === 'failed'">Reset</el-button>
        <el-tooltip effect="dark" content="Currently testing the account"
                    placement="left-start" :disabled="!['TO_TEST', 'TESTING'].includes(account.tested)">
        <div class="inline-block ml-2">
          <el-button size="mini" icon="el-icon-view"
                     v-if="account && canTestAccount(account)"
                     :disabled="['TO_TEST', 'TESTING'].includes(account.tested) && false"
                     :loading="['TO_TEST', 'TESTING'].includes(account.tested) && false"
                     @click="test" type="primary">
            Test
          </el-button>
        </div>
        </el-tooltip>
      </div>
    </div>
    <div class="columns">
      <div class="column w-full" v-if=" ['failed','failed_retry'].indexOf($store.state.emailAccount.account.status) > -1 && loaded">
        <el-alert type="error" :closable="false">Email account has an error.</el-alert>
      </div>
      <div class="column w-full" v-if="!$store.state.emailAccount.account.enabled && loaded">
        <el-alert type="warning" :closable="false">Email account is not enabled</el-alert>
      </div>
      <div class="column w-full" v-if="!$store.state.emailAccount.account.is_active && loaded">
        <el-alert type="warning" :closable="false">Email account is  not active </el-alert>
      </div>
      <div class="column w-full">
        <el-tabs class="custom-tabs-v1" v-if="loaded"  v-model="activeTabName" @tab-click="tabsChanged" ref="tabs">
          <el-tab-pane label="All" name="all">
            <email-list type="all" :account-id="$store.state.emailAccount.account.id" />
          </el-tab-pane>
          <el-tab-pane label="Incoming" name="incoming">
            <email-list type="incoming" :account-id="$store.state.emailAccount.account.id" />
          </el-tab-pane>
          <el-tab-pane label="Sent" name="sent">
            <email-list type="sent" :account-id="$store.state.emailAccount.account.id" />
          </el-tab-pane>
          <el-tab-pane label="Orphans" name="orphans">
            <email-list type="orphan" :account-id="$store.state.emailAccount.account.id" />
          </el-tab-pane>
          <el-tab-pane label="Account Details" name="info">
            <trieste-card title="Details">
              <template v-slot:header-right>
                <el-button type="success" icon="el-icon-edit" size="small" @click="show_settings = true" v-if="$store.getters['auth/hasPermission']('client.update') && loaded">Edit</el-button>
              </template>
              <div class="p-4">
                <trieste-info-column label="First name" :value="$store.state.emailAccount.account.first_name"  />
                <trieste-info-column label="Last name" :value="$store.state.emailAccount.account.last_name"  />
                <trieste-info-column label="status">
                  <template v-if="$store.state.emailAccount.account.exception_string && $store.state.emailAccount.account.exception_string.length">
                    {{ $store.state.emailAccount.account.exception_string }}
                    <div class="text-right" v-if="$store.state.emailAccount.account.num_exceptions >= 10">
                      <el-button type="primary" size="mini" icon="el-icon-refresh-left" @click="resetEmail">Reset</el-button>
                    </div>
                  </template>
                  <template v-else>
                    {{ $store.state.emailAccount.account.status }}
                  </template>
                </trieste-info-column>
              </div>
            </trieste-card>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>


    <trieste-dialog
      title="View Password"
      v-if="$store.getters['auth/hasPermission']('emailaccount.update') && loaded"
      :show.sync="password.open">

      <div class="p-5">
        <div class="flex">
          <el-input v-model="password.text" :disabled="password.running" />
          <el-button
            :disabled="password.running"
            @click="() => fetchPassword()"
            :loading="password.running" type="info">Reveal</el-button>
        </div>
      </div>


      <template v-slot:footer-right>
        <el-button
          :disabled="password.running"
          @click="() => password.open = false"
        >Close</el-button>
      </template>
    </trieste-dialog>

    <create-edit :show.sync="show_settings" :account="account" v-if="loaded" @success="getAccount"></create-edit>
  </div>
</template>
<script>
  import EmailList from "@/components/views/emails/EmailList";
  import CreateEdit from "./CreateEdit";
  import tabPosition from '@/components/mixins/tab_position';
  import { formatResponseErrorMessage } from "@/utils/helpers";

  export default {
    name: 'ViewClient',
    components: {CreateEdit, EmailList},
    mixins: [tabPosition],
    data() {
      return {
        password: {
          open: false,
          text: '',
          running: false,
        },
        loading: false,
        loaded: false,
        account: {},
        show_settings: false,
        activeTabName: 'incoming',
      }
    },
    mounted() {
      if(!this.$store.getters['auth/hasPermission']('emailaccount.read')) {
        this.$message.error('You do not have permissions to view email accounts.');
        this.$router.push('/');
      } else {
        this.getAccount()
      }
    },
    methods: {
      openPasswordReveal(){
        if(this.account.is_gmail || this.account.is_microsoft){
          this.$message.info('We cannot store password for Gmail or Microsoft Accounts as they are authenticated outside of the system.');
          return;
        }
     this.password.text = '';
     this.password.open = true;
      },
      fetchPassword(){
        const vm = this;
        vm.password.running = true;
        vm.password.text = '';
        vm.$http.get(`/v1/companies/${this.$store.state.company.company.id}/email-accounts/${this.account.id}/reveal-password`)
          .then((response) => {
            vm.password.running = false;
            vm.password.text = response.data.data.password;
          }).catch(() => {
          vm.password.running = false;
        })
      },
      getAccount() {
        this.loading = true;
        this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
          this.$store.dispatch('emailAccount/getAccount', this.$route.params).then((emailAccount) => {
            this.account = emailAccount;
            this.$store.commit('global/setBreadcrumbs', [
              {
                path: '/',
                label: company.name
              },
              {
                path: `/company/${company.id}/email-accounts`,
                label: 'Email accounts'
              },
              {
                label: emailAccount.email
              }
            ])
            this.loaded = true;
          })
        })
        .catch(e => {
          this.$message.error('Failed to load the email account.');
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        })
      },
      resetEmail() {
        this.loading = true;
        let url = `/v1/companies/${this.$store.state.company.company.id}/email-accounts/${this.account.id}/reset-error`;
        this.$http.put(url, {})
          .then(() => {
            this.$message.success('Successfully reset the email account. It is now active.');
            this.getAccount()
          })
          .catch((err) => {
            let message = formatResponseErrorMessage(err, 'resetEmail')
            this.$message.error(message)
            console.error(err.toJSON(), message)
          })
          .finally(() => {
            this.loading = false;
          })
      },
      test() {
        this.loading = true
        this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/email-accounts/test`, {
          ids: [this.account.id]
        }).then(() => {
          this.$message.success('Email account test has been completed successfully.')
          this.getAccount()
        })
          .catch((e) => {
            const error = e.response?.data?.message || 'Failed to test the email account.'
            this.$message.error(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      canTestAccount(account) {
        return (this.$store.getters['auth/hasPermission']('emailaccount.update') || account.owner_id === this.$store.state.auth.user.id)
      }
    }
  }
</script>
